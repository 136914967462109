<template>
	<div>
		<a-modal :visible="visible" :title="title" :closable="false">
			<template slot="footer">
				<a-button key="back" @click="onCancel(true)"> Cancelar </a-button>
				<a-button key="submit" type="primary" @click="handleSubmit"> {{ submitLabel }} </a-button>
			</template>
			<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
				<a-form layout="vertical" :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-md-6">
							<a-form-item label="Marca">
								<a-select
									v-decorator="[
										'motorcycle_brand_id',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
								>
									<a-select-option v-for="(brand, index) in motorcycleActiveBrandList" :key="index" :value="brand.id">{{ brand.name }}</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div class="col-md-6">
							<a-form-item label="Modelo">
								<a-input
									v-decorator="[
										'name',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
					</div>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>
<script>
//
import utilities from '@/services/utilities'
import { mapGetters } from 'vuex'

export default {
	name: 'insertOrUpdateMotorcycleModel',
	components: {},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		payload: {
			type: Object,
			default: function () {
				return {}
			},
		},
		requireReload: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			// loader
			spinnerLoaderLabel: 'Actualizando información...',
			spinnerLoader: false,
			form: this.$form.createForm(this),
			submitLabel: '',
			brands: [],
		}
	},
	computed: {
		...mapGetters('motorcycleBrand', ['motorcycleActiveBrandList']),
	},
	beforemounted() {
		if (this.motorcycleActiveBrandList.length == 0) {
			this.$store.dispatch('motorcycleBrand/GET_BRANDS', { controlTaller: false })
		}
	},
	methods: {
		onCancel(isFromCloseButton = false) {
			// console.log('handleCancel')
			if (this.requireReload && !isFromCloseButton) {
				this.$emit('reloadList')
			}
			this.$emit('closeModal')
			this.form.resetFields()
		},
		handleSubmit(e) {
			// console.log('handleSubmit')
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					//
					this.spinnerLoader = true
					let payload = {
						...values,
					}
					if (utilities.objectValidate(this.payload, 'motorcycle_model_id', false)) {
						payload.id = this.payload.motorcycle_model_id
						this.$store
							.dispatch('motorcycleModels/UPDATE_MODEL', payload)
							.then((response) => {
								// console.log('motorcycles/UPDATE_MODEL::response->', response)
								this.spinnerLoader = false
								this.onCancel()
							})
							.catch((error) => {
								this.spinnerLoader = false
							})
					} else {
						// console.log('payload', payload)
						this.$store
							.dispatch('motorcycleModels/CREATE_MODEL', { payload })
							.then((response) => {
								// console.log('motorcycles/CREATE_MODEL::response->', response)
								this.spinnerLoader = false
								this.onCancel()
							})
							.catch((error) => {
								this.spinnerLoader = false
							})
					}
				}
			})
		},
	},
	watch: {
		payload(newValue) {
			// console.log('watch::payload::newValue-->', newValue)
			setTimeout(() => {
				if (utilities.objectValidate(newValue, 'motorcycle_brand_id', false)) {
					this.form.setFieldsValue({
						motorcycle_brand_id: newValue.motorcycle_brand_id,
						name: newValue.model_name,
					})
					this.submitLabel = 'Actualizar'
				} else {
					this.submitLabel = 'Guardar'
				}
			}, 100)
		},
		visible(isVisible) {
			if (isVisible && this.motorcycleActiveBrandList.length == 0) {
				this.$store.dispatch('motorcycleBrand/GET_BRANDS', { controlTaller: false })
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>